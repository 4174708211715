import { useStyleRegister } from '@ant-design/cssinjs';
import { warning } from 'rc-util';
import { useContext } from 'react';
import { ConfigContext } from '../../config-provider/context';
import { genCommonStyle, genLinkStyle } from '../../style';
import { mergeToken, statisticToken, useToken } from '../internal';
export default function genComponentStyleHook(component, styleFn, getDefaultToken, options) {
    return (prefixCls) => {
        const [theme, token, hashId] = useToken();
        const { getPrefixCls, iconPrefixCls, csp } = useContext(ConfigContext);
        const rootPrefixCls = getPrefixCls();
        // Shared config
        const sharedConfig = {
            theme,
            token,
            hashId,
            nonce: () => csp === null || csp === void 0 ? void 0 : csp.nonce,
        };
        // Generate style for all a tags in antd component.
        useStyleRegister(Object.assign(Object.assign({}, sharedConfig), { path: ['Shared', rootPrefixCls] }), () => [
            {
                // Link
                '&': genLinkStyle(token),
            },
        ]);
        return [
            useStyleRegister(Object.assign(Object.assign({}, sharedConfig), { path: [component, prefixCls, iconPrefixCls] }), () => {
                const { token: proxyToken, flush } = statisticToken(token);
                const customComponentToken = Object.assign({}, token[component]);
                if (options === null || options === void 0 ? void 0 : options.deprecatedTokens) {
                    const { deprecatedTokens } = options;
                    deprecatedTokens.forEach(([oldTokenKey, newTokenKey]) => {
                        var _a;
                        if (process.env.NODE_ENV !== 'production') {
                            warning(!(customComponentToken === null || customComponentToken === void 0 ? void 0 : customComponentToken[oldTokenKey]), `The token '${String(oldTokenKey)}' of ${component} had deprecated, use '${String(newTokenKey)}' instead.`);
                        }
                        // Should wrap with `if` clause, or there will be `undefined` in object.
                        if ((customComponentToken === null || customComponentToken === void 0 ? void 0 : customComponentToken[oldTokenKey]) || (customComponentToken === null || customComponentToken === void 0 ? void 0 : customComponentToken[newTokenKey])) {
                            (_a = customComponentToken[newTokenKey]) !== null && _a !== void 0 ? _a : (customComponentToken[newTokenKey] = customComponentToken === null || customComponentToken === void 0 ? void 0 : customComponentToken[oldTokenKey]);
                        }
                    });
                }
                const defaultComponentToken = typeof getDefaultToken === 'function'
                    ? getDefaultToken(mergeToken(proxyToken, customComponentToken !== null && customComponentToken !== void 0 ? customComponentToken : {}))
                    : getDefaultToken;
                const mergedComponentToken = Object.assign(Object.assign({}, defaultComponentToken), customComponentToken);
                const componentCls = `.${prefixCls}`;
                const mergedToken = mergeToken(proxyToken, {
                    componentCls,
                    prefixCls,
                    iconCls: `.${iconPrefixCls}`,
                    antCls: `.${rootPrefixCls}`,
                }, mergedComponentToken);
                const styleInterpolation = styleFn(mergedToken, {
                    hashId,
                    prefixCls,
                    rootPrefixCls,
                    iconPrefixCls,
                    overrideComponentToken: customComponentToken,
                });
                flush(component, mergedComponentToken);
                return [
                    (options === null || options === void 0 ? void 0 : options.resetStyle) === false ? null : genCommonStyle(token, prefixCls),
                    styleInterpolation,
                ];
            }),
            hashId,
        ];
    };
}
